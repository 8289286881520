var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-input",
        {
          attrs: {
            value: _vm.fileNames,
            size: _vm.size,
            readonly: "",
            placeholder: _vm.placeholder,
            title: _vm.fileNames,
          },
        },
        [
          _c("el-button", {
            attrs: { slot: "append", size: _vm.size, icon: "el-icon-search" },
            on: { click: _vm.replaceUpload },
            slot: "append",
          }),
        ],
        1
      ),
      _c("input", {
        staticStyle: { display: "none" },
        attrs: { type: "file", id: "InputUpload", multiple: _vm.multiple },
        on: { change: _vm.changeInput },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }