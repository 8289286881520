









import { Component, Vue, Watch, Prop, Model } from 'vue-property-decorator';
import StorageUtil from 'global-ui/packages/utils/StorageUtil';
import FileApi from '@/api/system/FileApi';
@Component({
  name: 'FileInput'
})
export default class extends Vue {
  @Prop()
  placeholder!: string;

  @Prop()
  size!: string;

  @Prop({ default: true })
  multiple!: boolean;

  @Model('change') value!: any[];

  token: string = '';
  loading: boolean = false;
  fileData: any = {};
  isValueChange: boolean = false; //判断是否是由于v-mode变化而引起的watch方法调用
  fileNames: string = '';

  created() {
    this.token = StorageUtil.getSessionStorage('token');
    this.getFileList(this.value);
  }

  //获取文件列表
  getFileList(fileIds) {
    this.loading = true;
    if (fileIds && fileIds.length) {
      FileApi.list({ ids: fileIds }).then((res: any) => {
        if (res.code == '1') {
          this.fileData = res.data;
          let fileNames = res.data.map(item => item.fileName);
          this.fileNames = fileNames.join(',');
        } else {
          this.$message.error(res.message);
        }
        this.loading = false;
      });
    } else {
      this.fileData = [];
      this.loading = false;
    }
  }
  //更新v-mode的值
  updateValue() {
    this.isValueChange = true;
    let fileIds: any[] = [];
    let fileNames: any[] = [];
    if (this.fileData) {
      this.fileData.forEach(item => {
        fileIds.push(item.fileId);
        fileNames.push(item.fileName);
      });
    }
    this.fileNames = fileNames.join(',');
    this.$emit('change', fileIds);
    this.$emit('success', this.fileData);
  }

  //文件替换
  replaceUpload(val, index) {
    (document.getElementById('InputUpload') as any).click();
  }

  changeInput(event) {
    let files = event.target.files;
    if (files && files.length) {
      let length = files.length;
      let result: any[] = [];
      for (let index = 0; index < files.length; index++) {
        FileApi.upload({ file: files[index] }).then(res => {
          result.push(res.data);
          length--;
          if (length == 0) {
            this.fileData = result;
            this.updateValue();
          }
        });
      }
    }
  }

  @Watch('value')
  valueWatcher(curVal, oldVal) {
    if (this.isValueChange) {
      this.isValueChange = false;
    } else {
      this.getFileList(curVal);
    }
  }
}
